<template>
	<div class="wrapper logistics">
		<div class="main-title">
			<div class="name">物流公司</div>
			<div class="right">
				<el-input class="search-input" placeholder="请输入关键字模糊搜索" v-model="query.keyword" @input="searchChange">
					<el-button type="primary" slot="append" icon="el-icon-search" @click="search()"></el-button>
				</el-input>
				<el-button type="primary" class="main-btn" @click="addLogistics">添加物流公司</el-button>
			</div>
		</div>
		<el-table class="main-table" :data="tableData" border header-row-class-name="table-header" highlight-current-row
			ref="editTable">
			<el-table-column label="序号" type="index" align="center" width="80"></el-table-column>
			<el-table-column label="物流公司名称" prop="name" align="center"></el-table-column>
			<el-table-column label="联系人" prop="contactName" align="center"></el-table-column>
			<el-table-column label="联系电话" prop="phone" align="center"></el-table-column>
			<el-table-column label="添加时间" prop="addTime" align="center" sortable></el-table-column>
			<el-table-column label="操作" align="center" class-name="table-operate">
				<template slot-scope="{row}">
					<el-button type="primary" size="mini" round @click="exitClick(row)">编辑</el-button>
					<el-button type="danger" size="mini" round @click="deleteClick(row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination background layout="prev, pager, next,total,jumper" :total="total" :current-page.sync="query.page"
			:page-size="query.pagesize" @current-change="getList"></el-pagination>
		<el-dialog :title="dialogTitle" :visible.sync="dialogVisible">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
				<el-form-item label="物流公司名称" prop="name">
					<el-input v-model="ruleForm.name"></el-input>
				</el-form-item>
				<el-form-item label="联系人" prop="contactName">
					<el-input v-model="ruleForm.contactName"></el-input>
				</el-form-item>
				<el-form-item label="联系电话" prop="phone">
					<el-input v-model="ruleForm.phone"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
					<el-button @click="dialogVisible = false">取 消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import {
		fetchList,
		saveOrUpdate,
		deleteLogisticsCompany
	} from "@/api/logisticsCompany"

	export default {
		data() {
			return {
				searchInput:'',
				ruleForm: {
					name: '',
					contactName: '',
					phone: ''
				},
				rules: {
					name: [{
						required: true,
						message: '请输入物流公司名称',
						trigger: 'blur'
					}],
					contactName: [{
						required: true,
						message: '请输入联系人',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: '请填写手机号',
						trigger: 'blur'
					}],
				},
				dialogTitle: "添加物流公司",
				dialogVisible: false,
				tableData: [],
				total: 10,
				query: {
					keyword: '',
					page: 1,
					pagesize: 10
				}
			}
		},
		mounted() {
			this.getList()

		},
		methods: {
			searchChange() {
			},
      search() {
        this.getList()
      },
      getList(){
				fetchList(this.query).then(res => {
					this.tableData = res.data.list
					this.total = res.data.total
				})
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						saveOrUpdate(this.ruleForm).then(res => {
							if (res.code === 0) {
								this.dialogVisible = false
								this.getList()
							}
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			exitClick(row) {
				this.dialogTitle = "编辑物流公司"
				this.dialogVisible = true
				this.ruleForm = row
			},
			addLogistics() {
				this.dialogTitle = "添加物流公司"
				this.dialogVisible = true
				this.ruleForm = {
					name: '',
					contactName: '',
					phone: ''
				}
			},
			deleteClick(row) {
				this.$confirm("确定要永久删除该行数据吗?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.tableData.splice(row, 1)
						deleteLogisticsCompany(row.id).then(res => {
							if (res.code === 0) {
								this.$message({
									type: "success",
									message: "删除成功!",
								});
								this.getList()
							}

						})
					})
					.catch(() => {});
			},
		}
	}
</script>

<style lang="scss" scoped>
	.main-title{
		margin: 0 0 20px 0;
		.right{
			display: flex;
			align-items: center;
			.main-btn{
				margin: 0 0 0 20px;
				padding:10px 20px;
			}
		}
	}
</style>
